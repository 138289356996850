import React from "react";
import { Link } from "gatsby";
// import Img from "gatsby-image";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import rcpm from "../../images/remuda-court-plat-map.jpg";
import "../../css/plans.css";

class TaylorParksPage extends React.Component {
  render(){

    return (
      <Layout>
        <SEO title="Plans" />
        <div className="container">

          <div className="row">
            <div className="col-sm mt-auto">
              <h1 className="mt-4">Remuda Court Subdivision</h1>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-sm">

              <p>In the country yet close to everything! This family friendly subdivision is right by the new Silver Ridge Elementary and Remuda Golf Course. Quiet yet close to convenience.</p>

              <div className="row mt-4 mb-4">
                <div className="col-sm-6">
                  <div>
                    <img src={rcpm} className="img-fluid" alt="alt"/>
                  </div>
                  <Link to="contact-us"><button type="button" className="btn btn-success btn-lg mt-3">REQUEST MORE INFO</button></Link>
                </div>
                <div className="col-sm-6">
                  <iframe title="Remuda Court Map"  border="0" width="100%" height="500" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.520692399242!2d-112.04752688440355!3d41.3216153080326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x875372aa48639479%3A0x0!2zNDHCsDE5JzE3LjgiTiAxMTLCsDAyJzQzLjIiVw!5e1!3m2!1sen!2sus!4v1518204237630" frameborder="0" allowfullscreen=""></iframe>
                </div>
              </div>
            </div>

          </div>

        </div>

      </Layout>
    )
  }
};

export default TaylorParksPage;
